import { IonHeader, IonImg, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import './RoomHeader.css';

type RoomHeaderProps = {
  roomId: string;
  userId: string;
  ownerId: string;
};

const RoomHeader: React.FC<RoomHeaderProps> = ({ roomId, userId, ownerId }) => {
  let history = useHistory();

  const toHome = () => {
    history.push('/');
    history.go(0);
  };

  return (
    // <IonToolbar>
    //   <IonTitle slot="start" onClick={toHome} class="title">
    //     Araari
    //   </IonTitle>

    //   {/* <>
    //       <IonInput
    //         slot="end"
    //         type="url"
    //         inputmode="search"
    //         class="input-bar"
    //         placeholder="Upload video by URL"
    //         onIonChange={(e) => setVideoUrl(e.detail.value!)}
    //         value={videoUrl}
    //         onSubmit={onSubmit}
    //       ></IonInput>
    //       <IonFabButton disabled={videoUrl === ''} slot="end" size="small" onClick={onSubmit}>
    //         <IonIcon icon={add}></IonIcon>
    //       </IonFabButton>
    //     </> */}

    // </IonToolbar>

    <IonHeader>
    <IonToolbar> 
      <IonImg src='/assets/araari-logo1.png'  onClick={toHome} class='logo-center' >Araari</IonImg>
    </IonToolbar>
  </IonHeader>

  );
};

export default RoomHeader;
