import {
  IonCol,
  IonContent,
  IonFabButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import copy from 'copy-to-clipboard';
import { arrowForward, clipboardOutline, enter, enterOutline, enterSharp, saveSharp, swapHorizontal } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { arrayUnion, db, rtdb } from '../services/firebase';
import './OnlineList.css';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


type OnlineListProps = {
  pane: string;
  roomId: string;
  userId: string;
  userList: Map<string, string>;
};

const OnlineList: React.FC<OnlineListProps> = ({ pane, roomId, userId, userList }) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const connectionRef = useRef<HTMLIonToastElement>(null);
  const [showConnectionChange, setShowConnectionChange] = useState(false);
  const [connectionMessage, setConnectionMessage] = useState('You joined the room');
  const [showCopied, setShowCopied] = useState(false);
  const { control, errors, setValue, getValues } = useForm({ mode: 'onChange' });
  const [showNameChange, setShowNameChange] = useState(false);

  // Listen for connection changes in the room
  useEffect(() => {
    const roomRef = rtdb.ref('/rooms/' + roomId);
    roomRef.on('child_added', (snapshot) => {
      if (snapshot.val().name !== undefined) {
        setConnectionMessage(snapshot.val().name + ' joined');
      }
    });

    roomRef.on('child_removed', (snapshot) => {
      if (snapshot.val().name !== undefined && snapshot.key !== userId) {
        setConnectionMessage(snapshot.val().name + ' left');
      }
    });

    return () => {
      roomRef.off('child_added');
      roomRef.off('child_removed');
    };
  }, [roomId, userId]);

  // Show toast whenever connection message changes
  useEffect(() => {
    setShowConnectionChange(true);
    connectionRef.current?.present();
  }, [connectionMessage]);

  const copyLink = () => {
    copy(window.location.href);
    setShowCopied(true);
  };

  // Update databases with new username
  const changeName = async () => {
    const newName = getValues('username');
    if (newName !== '') {
      const snapshot = await db.collection('users').doc(userId).get();
      const prevName = snapshot.data()?.name;
      db.collection('users').doc(userId).update({
        name: newName,
      });
      rtdb.ref('/rooms/' + roomId + '/' + userId).set({ name: newName });

      // Send 'nameChange' request for all clients to get a message about the name change
      db.collection('rooms')
        .doc(roomId)
        .update({
          requests: arrayUnion({
            createdAt: Date.now(),
            senderId: userId,
            data: { prev: prevName, curr: newName },
            type: 'nameChange',
          }),
        });

      setShowNameChange(true);
    }
  };

  const onEnter = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === 'Enter') {
      if (!errors.username) {
        changeName();
        setValue('username', '');
      }
    }
  };

  return (

    <IonContent style={{ display: pane === 'online' ? null : 'none' }} class="online-content">
      <></>
      <IonListHeader class="settings-header">Change Username</IonListHeader>
      <IonItem class="name-item">
        {/* <IonLabel>Change Username</IonLabel> */}
        <Controller
          name="username"
          render={({ onChange, onBlur, value }) => (
            <IonToolbar class="name-toolbar" >
            <IonInput
              onIonChange={onChange}
              onKeyDown={(e) => onEnter(e)}
              placeholder="New name"
              maxlength={20}
              value={value}
              class="name-input"
            ></IonInput>
            <IonFabButton slot="end" size="small" onClick={changeName} class="save-button">
            <IonIcon icon={arrowForward}></IonIcon>
            </IonFabButton>
            </IonToolbar>
          )}
          control={control}
          rules={{
            minLength: { value: 4, message: '⚠ Must be at least 4 characters long' },
            pattern: { value: /^\w+$/, message: '⚠ Must be alphanumeric' },
          }}
        ></Controller>
      </IonItem>
      <ErrorMessage name="username" errors={errors} as="span" className="error-message"></ErrorMessage>
      {/* <IonGrid class="about-grid">
        <IonRow>
          <IonCol>
            <span>Any feedback, questions, or issues? </span>
            <span role="img" aria-label="VEPLAY">
              🐢🐢
            </span>
          </IonCol>
        </IonRow>
        <IonRow class="externals-row">
          <IonCol size="3"></IonCol>
          <IonCol size="3">
            <IonRouterLink href="https://github.com/shuang854/VEPLAY" target="_blank">
              <IonIcon icon={logoGithub} class="about-icons"></IonIcon>
            </IonRouterLink>
          </IonCol>
          <IonCol size="3">
            <IonRouterLink href="https://discord.gg/NEw3Msu" target="_blank">
              <Icon icon={discordIcon} className="about-icons"></Icon>
            </IonRouterLink>
          </IonCol>
          <IonCol size="3"></IonCol>
        </IonRow>
      </IonGrid> */}
      <IonToast
        color="primary"
        duration={2000}
        isOpen={showNameChange}
        onDidDismiss={() => setShowNameChange(false)}
        position="top"
        message="Username changed successfully"
      ></IonToast>
      <></>
      <IonListHeader>Online</IonListHeader>
      <IonList class="online-list">
        {Array.from(userList.values()).map((user) => {
          return (
            <IonItem key={user} class="online-item" lines="none">
              <IonLabel class="online-label">{user}</IonLabel>
            </IonItem>
          );
        })}
      </IonList>
      <IonRow>
        <IonCol class="clipboard-col">
          <IonListHeader>Invite friends!</IonListHeader>
          <IonToolbar class="clipboard-toolbar">
            <IonInput readonly value={window.location.href} ref={inputRef} class="clipboard-input"></IonInput>
            <IonFabButton slot="end" size="small" onClick={copyLink} class="send-button">
              <IonIcon icon={clipboardOutline}></IonIcon>
            </IonFabButton>
          </IonToolbar>
        </IonCol>
      </IonRow>
      <IonToast
        color="primary"
        duration={2000}
        isOpen={showCopied}
        onDidDismiss={() => setShowCopied(false)}
        position="top"
        message="Room link copied"
      ></IonToast>
      <IonToast
        color="primary"
        duration={500}
        isOpen={showConnectionChange}
        onDidDismiss={() => setShowConnectionChange(false)}
        position="top"
        message={connectionMessage}
        ref={connectionRef}
      ></IonToast>
      <></>
      
    </IonContent>
  );
};

export default OnlineList;
