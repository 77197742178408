// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import 'firebase/database';
// import 'firebase/firestore';
// import { Database } from "firebase/database";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQ11DlILrI6VSmMxrMIpB8K4aNT-02I4A",
  authDomain: "v-play-8d2fd.firebaseapp.com",
  databaseURL: "https://v-play-8d2fd-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "v-play-8d2fd",
  storageBucket: "v-play-8d2fd.firebasestorage.app",
  messagingSenderId: "87668272666",
  appId: "1:87668272666:web:955659012527affa55aba3",
  measurementId: "G-C3G3NDY73V"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const rtdb = firebase.database();
export const auth = firebase.auth();

export const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export const currTime = firebase.firestore.Timestamp.fromDate(new Date());
export const sessionPersist = firebase.auth.Auth.Persistence.SESSION;
export const increment = firebase.database.ServerValue.increment(1);
export const decrement = firebase.database.ServerValue.increment(-1); 

export const arrayUnion = (object: any) => {
  return firebase.firestore.FieldValue.arrayUnion(object);
};